import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocationDetails } from '~hooks/useLocationDetails';
import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';
import IntakeActions from '~redux/intake';
import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';
import { usePrevious } from '~hooks/common';
import { getListingRedirect } from '~helpers/common';

import Loader from '~components/loader';
import Breadcrumb from '~components/breadcrumbs';

import { useDefaultWish } from '~containers/wishes/default-wish';
import WishAddress from '~containers/wishes/form/address';
import WishFields from '~containers/wishes/form/fields';
import { getWorkersCount } from './utils';

import '../style.scss';

const ClientIntake = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const listingRedirect = useListingRedirect();

  const { signUp } = useContext(AuthContext);
  const { user: authUser } = useAuth0();
  const { user: backendUser, getOwnData, updateCustomer } = useContext(UserContext);

  useLayout({ displayCities: false });
  useSeo(intl.formatMessage({ id: 'containers.intake.client.title', defaultMessage: 'Your wishes' }));

  const personId = !authUser ? null : authUser['https://sr2.ca/claims/user_metadata'].uuid;
  const storedIntakeWish = useSelector((state) => state.intake.wish);
  const defaultWish = useDefaultWish();
  const wish = storedIntakeWish || defaultWish;
  const locale = useSelector((state) => state.domainConfig.translations);
  const [showFields, setShowFields] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [defaultPrice, setDefaultPrice] = useState(wish?.maximumRate?.amount || null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingWorkersCount, setIsLoadingWorkersCount] = useState(false);

  const locationIdInUrlParam = queryString.parse(location.search).locationId;
  const showFieldsInParam = queryString.parse(location.search).showFields;

  if (!storedIntakeWish) {
    wish.languages = [locale.substr(0, locale.indexOf('_'))];
  }

  const { locationDetails, pricingDetails, isLoading, requestLocationByID } = useLocationDetails(
    locationIdInUrlParam || null
  );
  const previousLocationDetails = usePrevious(locationDetails);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingInitial(false);
    }
  }, [isLoading]);

  useEffect(async () => {
    if (locationDetails && pricingDetails) {

      if (!defaultPrice) {
        setDefaultPrice((pricingDetails.maxPrice - pricingDetails.minPrice) * 0.75 + pricingDetails.minPrice);
      }
      const newWish = {  ...wish, address: locationDetails, maximumRate: { amount: pricingDetails.maxPrice, currency: pricingDetails.currency }};

      dispatch(IntakeActions.setAccountType('client'));
      dispatch(IntakeActions.setWish({ ...newWish, published: true }));

      if(showFieldsInParam) {
        setShowFields(true);
        return;
      }

      if(previousLocationDetails?.place_id !== locationDetails.place_id) {
        setIsLoadingWorkersCount(true);
        const resultsCount = await getWorkersCount(locationDetails, pricingDetails, wish);
        if(resultsCount < 4) {
          setShowFields(true);
        } else {
          dispatch(IntakeActions.setWish({ ...newWish, timeframes: [], languages: [], published: false }));
          const redirectAddress = getListingRedirect('client', locationDetails.place_id);
          
          const isUserAlready = await getOwnData(personId);
          if (!isUserAlready) {
            history.push(redirectAddress);
            return;
          }

          const newUser = { ...backendUser, wish: { ...newWish, timeframes: [], languages: [], published: false } };
          await updateCustomer(newUser);
          history.push(redirectAddress);
          
        }
        setIsLoadingWorkersCount(false);
      }
    }
  }, [locationDetails, pricingDetails]);

  const handleAddressSubmit = async (newAddress) => {
    requestLocationByID(newAddress.place_id);
  };

  const handleSubmit = async (newWish) => {
    setIsSubmitting(true);
    dispatch(IntakeActions.setWish(newWish));
    dispatch(IntakeActions.setAccountType('client'));

    const isUserAlready = await getOwnData(personId);

    if (!isUserAlready) {
      signUp();
      return;
    }

    const newUser = { ...backendUser, wish: newWish };
    await updateCustomer(newUser);

    setIsSubmitting(false);
    if (storedIntakeWish.referrer) {
      history.push(referrer);
      return;
    }

    const redirectAddress = getListingRedirect('client', newWish.address.locationId);
    history.push(redirectAddress);
  };

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to="/intake/client" index={2}>
                {intl.formatMessage({
                  id: 'containers.intake.client.breadcrumb',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <div className="c-page l-container">
        <div className="c-page__main">
          <div className="c-page__header c-page__header--minimal">
            <h1 className="l-headline--primary l-center">
              {intl.formatMessage({ id: 'containers.intake.client.headline', defaultMessage: 'Your wishes' })}
            </h1>
          </div>
          <div className={cx('c-form--intake', {'c-form--intake-show-fields': showFields})}>
          
            {!location?.state?.isFromHome && <WishAddress
              defaultAddress={locationDetails}
              onSubmit={handleAddressSubmit}
              isDataLoading={isLoadingInitial}
            />}
            {!showFields  && (isLoading || isLoadingWorkersCount) ? (
              <div className="l-center l-mt2">
                <Loader />
              </div>
            ) : null}
            {(showFields || location?.state?.isFromHome) && (
              <WishFields
                wish={wish || location?.state?.wish}
                pricingDetails={pricingDetails || location?.state?.pricingDetails }
                defaultPrice={defaultPrice || location?.state?.defaultPrice}
                translationsContext="create"
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientIntake;
